<template>
  <router-view></router-view>
</template>

<script>
export default {
  components: {},
  created() {
    this.$store.dispatch("logistic/getStores");
  },
  mounted() {
    // LogisticService.getRucData('10704137945').then(
    //   res => {
    //     console.log(res)
    //   }
    // );
  }
};
</script>

<style>
</style>
